@font-face {
font-family: 'GeistSans';
src: url(/_next/static/media/e11418ac562b8ac1-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: 'GeistSans Fallback';src: local("Arial");ascent-override: 85.83%;descent-override: 20.52%;line-gap-override: 9.33%;size-adjust: 107.19%
}.__className_3a0388 {font-family: 'GeistSans', 'GeistSans Fallback'
}.__variable_3a0388 {--font-geist-sans: 'GeistSans', 'GeistSans Fallback'
}

